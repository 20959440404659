/**
Kentico Forms widget
This is only concerned with general form structure
as implement by both Kentico forms and the bespoke Kentico form widget

No actual form elements (inputs etc) should be styled here unless
absolutely required.

See:
/_core/ui/_forms.scss


 */

.kentico-forms {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 56px;
    margin-bottom: 0;
    padding-top: 56px;
    padding-bottom: 56px;
    background-color: map_get($colours, 'n-9');

    .widget-title {
        color: map_get($colours, 'p-4');
        text-transform: uppercase;
        margin-bottom: 7px;
    }

    .widget-desc {
        margin-bottom: 30px;
    }

    .form-field {
        margin-bottom: 35px;
    }

    /*
    Kentico class for checkbox controls
     */
    //Wrapping class for radio items
    .ktc-checkbox-list {
        display: block;

        //Individual radios
        .ktc-checkbox {
            display: block;
            margin-bottom: 10px;
        }

        label {
            font-weight: 400;
        }
    }

    /*
    Kentico class for radio controls
     */
    //Wrapping class for radio items
    .ktc-radio-list {
        display: block;

        //Individual radios
        .ktc-radio {
            display: block;
            margin-bottom: 10px;
        }

        label {
            font-weight: 400;
        }
    }

    /*
    Kentico field error
     */
    .field-validation-error {
        padding-top: 10px;
        color: map_get($colours, 's-1');
    }

    /*
    Because of course the button styles in the
    form are different to styleguide
     */
    input[type='submit']{
        width: 100%;
    }

    .explanation-text {
        @include font(14px, 23px);
        margin: 20px 0;
        color: map_get($colours, 'p-2');

    }

    //992 +
    @include media-breakpoint-up(lg) {

        margin-top: 100px;
        margin-bottom: 0;
        padding-top: 90px;
        padding-bottom: 100px;

        .widget-title {
            margin-bottom: 26px;
        }

        .widget-desc {
            margin-bottom: 52px;
        }

        /*
        Because of course the button styles in the
        form are different to styleguide
         */
        input[type='submit']{
            max-width: 286px;
        }
    }
}
