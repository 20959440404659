/**
Text only widget, generally used as first text widget on a content page
 */

.intro-text{

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 55px;
    margin-bottom: 45px;
    overflow: hidden;
    color: map_get($colours, 'p-2');

    .section-title {
        text-transform: uppercase;
        margin-bottom: 29px;
    }

    .content {
        @include font(22px, 35px);
        padding-bottom: 17px;

        * {
            font-size: inherit;
            line-height: inherit;
        }
    }

    /*
    Underline effect
     */
    .row:after {
        content: '';
        display: block;
        width: calc(100% - #{$grid-gutter-width});
        height: 4px;
        margin-left: $grid-gutter-width / 2;
        background-color: map_get($colours, 'p-4');
    }


    //992 +
    @include media-breakpoint-up(lg) {
        overflow: hidden;

        .section-title {
            margin-bottom: 26px;
        }

        .content {
            @include font(26px, 39px);
            padding-bottom: 23px;
        }

        .row {
            position: relative
        }

        /*
        Underline effect
         */
        .row:after {
            @include make-col-ready();
            @include make-col-offset(2);
            position: absolute;
            left: $grid-gutter-width / 2;
            bottom: 0;
            width: 200vw;
            height: 6px;
        }
    }
}
