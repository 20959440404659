/**
Documentation
 */

.contact-methods {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 57px;
    margin-bottom: 46px;

    .widget-title {
        @include font(28px, 36px);
        text-transform: uppercase;
        padding-bottom: 23px;
        margin-bottom: 32px;
        color: map_get($colours, 'p-2');
        border-bottom: solid 4px map_get($colours, 'p-4');
    }
    .items{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        :first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
    /*
    Individual tile
     */
    .contact-method-tile {
        width: 100%;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
        .inner {
            display: flex;
            align-items: flex-start;
            
            padding: 20px 20px;
            height: 100%;
            min-height: 240px;
            border: 1px solid map_get($colours, 'n-7');
            border-radius: 5px;
            color: map_get($colours, 'p-2');
        }

    }

    .contact-method-tile.disable {
        
        .icon, 
        p
        {
            color: map_get($colours, 'd-1');
        }

        a {
            pointer-events: none;
            color: map_get($colours, 'd-1');
        }
    }
    
    .contact-method-tile.ace-tile,
    .contact-method-tile.whatsapp-tile {
        display: block;
    }

    .title-block {
        display: flex;
        margin-bottom: 10px;

        .icon {
            width: 34px;
            height: 34px;
            margin-right: 18px;
        }

        p {
            @include font(18px, 30px);
            font-weight: 700;
            color: inherit;
            margin-bottom: 0;
        }
    }

    .content-block {
        a {
            display: block;
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {

        margin-top: 88px;
        margin-bottom: 90px;

        .widget-title {
            @include font(48px, 56px);
            padding-bottom: 33px;
            margin-bottom: 49px;
            border-bottom-width: 6px;
        }

        /*
       Individual tile
        */
        .items {

            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            .contact-method-tile:first-child .inner {
                margin-top: initial;
            }
    
            .contact-method-tile:last-child .inner {
                margin-bottom: initial;
            }
        }
        .contact-method-tile {
            flex-basis: calc(33.33% - 20px);
        }

        .contact-method-tile.ace-tile,
        .contact-method-tile.whatsapp-tile {
            display: none;
        }

        .title-block {
            margin-bottom: 30px;

            .icon {
                width: 40px;
                height: 40px;
            }

            p {
                @include font(26px, 39px);
            }
        }

        .content-block {
            @include font(18px, 31px);

            p {
                font-size: inherit;
                line-height: inherit;
            }
            a {
                @include font(16px, 24px);
            }
        }
    }
}
