/**
Documentation
 */

.tabbed-returns {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 70px;
    margin-bottom: 15px; //Less to account for pagination
    width: 100%;

    /*
    Header with buttons
     */
    $tabHeaderHeight: 68px;
    $tabHeaderHeight_lg: 78px;

    .tab-header {
        height: $tabHeaderHeight;
        display: flex;
    }

    .btn-tab {
        @include font(16px, 26px);
        @include btnReset();
        width: 100%;
        display: flex;
        align-items: center;
        height: $tabHeaderHeight;
        padding: 0 20px;

        background-color: map_get($colours, 'n-8');
        color: map_get($colours, 'n-5');
        border-radius: 5px 5px 0 0;
        text-align: left;
        font-weight: 700;

        &:hover {
            color: map_get($colours, 'p-2');
        }

        &.active {
            background-color: map_get($colours, 'n-9');
            color: map_get($colours, 'p-2');
        }
    }

    /*
    Tab Wrapping elem
     */
    .tab-wrapper {
        background-color: map_get($colours, 'n-9');
    }

    /*
    Hide tab content by default
    
    Removed and moved to JS to support loading correctly in IE with display none set.
     */
    .tab-content {
        &.inactive {
            display: none;    
        }
    }
    /*
    Tabulator specific elements + classes
     */
    .tabulator {
        background-color: transparent;
        border: none;
    }
    .tabulator-footer { background-color: white; }


    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 90px;
        margin-bottom: 46px; //Less to account for pagination

        .tab-header {
            height: $tabHeaderHeight_lg;
        }

        .btn-tab {
            @include font(18px, 30px);
            height: $tabHeaderHeight_lg;
            padding: 0 32px;
        }

        /*
        Tab Wrapping elem
         */
        .tab-wrapper {
            padding: 0;
            background-color: transparent;
        }

        .table-wrap {
            padding: 15px;
            background-color: map_get($colours, 'n-9');
            border-radius: 0 0 5px 5px;
        }
    }
}
