/**
Document Listing Widget and document item
This file contains CSS for two object:
Document Item - this is a single item for a document, with content and links to files
Document Listing - this is the placeable kentico widget with title element that renders
a list of document items
They have been kept intentionally seperate, so the document item class can be re-used.
 */


.document-item {

    padding: 16px $grid-gutter-width;
    margin-bottom: 10px;
    border: solid 1px map_get($colours, 'n-8');
    border-radius: 5px;

    .detail-wrap {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .file-icon {
        position: absolute;
        top: 0;
        left: 0;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 15px;
        width: 27px;
        height: 27px;
    }

    .document-name {
        @include font(18px, 30px);
        margin-bottom: 4px;
        padding-left: 45px;
        font-weight: 700;
        color: map_get($colours, 'p-2');
    }

    .document-desc {
        @include font(16px, 26px);
        flex-basis: 100%;
        margin-bottom: 10px;
    }

    .download {
        display: block;
        margin-bottom: 25px;
    }
    .btn-default {
        display: block;
        width: 100%;
    }

    //992 +
    @include media-breakpoint-up(lg) {

        display: flex;
        padding: $grid-gutter-width;

        .detail-wrap {
            padding-right: 20px;
        }

        .file-icon {
            flex-grow: 0;
            flex-shrink: 0;
            position: relative;
            margin-right: 45px;
            height: 52px;
            width: 52px;
        }

        .document-name {
            @include font(18px, 21px);
            flex-shrink: 1;
            margin-bottom: 4px;
            padding-left: 0;
        }

        .document-desc {
            @include font(18px, 26px);
            margin-bottom: 0;
        }


        .link-wrap {
            display: flex;
            align-items: center;
            margin-left: auto;
        }

        .download {
            width: 36px;
            height: 36px;
            margin-bottom: 0;
            margin-right: 24px;

            .icon {
                width: 36px;
                height: 36px;
            }
        }

        .btn-default {
            width: 200px;
            max-height: 50px;
            padding: 8px 20px 11px 20px;
        }
    }
}


.document-listing {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 40px;
    margin-bottom: 40px;

    .widget-title {
        @include font(28px, 36px);
        margin-bottom: 32px;
        font-family: $fontSecondary;
        font-weight: 700;
        color: map_get($colours, 'p-2');
        text-transform: uppercase;
    }

    //992 +
    @include media-breakpoint-up(lg) {

        margin-top: 90px;
        margin-bottom: 90px;

        .widget-title {
            @include font(38px, 66px);
        }
    }
}
