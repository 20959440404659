
/**
Documentation
 */

body {
    /**
    Default, Mobile first styles
     */
    &.nav-open {
        height: 100vh;
        overflow: hidden;
    }

    @include media-breakpoint-down(md){
        padding-top: map_get($globalHeader, 'moduleHeight');

        //When notification is active
        &.notification-active {
            padding-top: map_get($globalHeader, 'moduleHeight') + map_get($notificationBanner, 'moduleHeight');

            #global-header {
                top: map_get($notificationBanner, 'moduleHeight');
            }

            #global-nav {
                top: map_get($globalHeader, 'moduleHeight') + map_get($notificationBanner, 'moduleHeight');
            }
        }
    }
}
