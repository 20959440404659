/**
Pull Quote widget
Generally includes text content on left, with optional image on right
Column structure is handled purely via CSS; so if no image is populated
layout adjusts accordingly
 */

.pull-quote {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    position: relative;
    padding-top: 64px;
    margin-top: 49px;
    margin-bottom: 55px;
    overflow: hidden;
    background-color: map_get($colours, 'p-1');

    /*
    Column Structure
    Default, with Image
     */
    .content-col {
        @include make-col-ready();
        @include make-col(12);
    }

    .img-col {
        @include make-col-ready();
        @include make-col(12);
        position: relative;

        /*
        To avoid awkward negative margins and offsets,
        lets use psuedo elements to create a white block
        at the end of the widget, to create the illusion that the
        image extends beyond the green background
        */
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: -$grid-gutter-width / 2;
            bottom: 0;
            z-index: 1;
            width: 100vw;
            height: 58px;
            background-color: white;
        }
    }


    .quotation {
        display: block;
        width: 23px;
        height: 18px;

        &.quotation-right {
            margin-left: auto;
        }
    }

    .quote-text {
        @include font(22px, 35px);
        margin-top: 28px;
        margin-bottom: 24px;
        color: map_get($colours, 'p-2');
    }

    .attribution {
        @include font(24px, 34px);
        margin-top: 10px;
        margin-bottom: 42px;
        font-family: $fontSecondary;
        color: map_get($colours, 'p-2');

        > p {
            font-size: inherit;
            line-height: inherit;
            margin-bottom: 0;
        }
    }

    .img-block {
        position: relative;
        z-index: 5;
    }


    //768 +
    @include media-breakpoint-up(md) {

        $imgOffset: 53px;
        padding-top: 0;
        margin-top: 96px;
        margin-bottom: 35px + $imgOffset; //value in Figma + offset
        overflow: visible;

        /*
        Column Structure
        Default, with Image
         */
        .content-col {
            @include make-col(5);
            @include make-col-offset(1);
        }

        /*
        No image
         */
        .content-col:last-child {
            @include make-col(8);
            @include make-col-offset(2);

            .content-wrap {
                padding-bottom: 25px;
            }
        }

        .img-col {
            @include make-col(5);
            @include make-col-offset(1);

            &:after {
                display: none;
            }
        }

        .content-col {
            display: flex;
            align-items: center;
        }

        .content-wrap {
            padding-top: 53px;
        }

        .quotation {
            width: 29px;
            height: 22px;
        }

        .quote-text {
            @include font(26px, 39px);
            color: map_get($colours, 'p-2');
        }

        .attribution {
            margin-top: 40px;
        }

        .img-block {
            top: $imgOffset;
        }



        /*
        Animated version
         */
        &[data-animate-onscroll='true']{

            /*
            Default state, before animation - image on RIGHT
             */
            @include animateCustom(#{'background-color'}, 1000ms );
            background-color: lighten(map_get($colours, 'p-1'), 10%);

            .img-block {
                @include animateCustom(#{'transform, opacity'}, 1000ms, 250ms );
                transform: translateY(20px) scale(0.95);
                transform-origin: 50% bottom;
                opacity: 0;
            }

            .quote-wrap {
                @include animateCustom(#{'transform, opacity'}, 1000ms );
                transform: translateX(-40px);
                opacity: 0;
            }

            .attribution {
                @include animateCustom(#{'transform, opacity'}, 1000ms, 250ms );
                transform: translateX(-40px);
                opacity: 0;
            }

            /*
            DOM loaded, animate 'end' state
             */
            &[data-animated='true']{
                background-color: map_get($colours, 'p-1');

                .img-block {
                    transform: translateX(0);
                    opacity: 1;
                }

                .quote-wrap,
                .attribution {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }
    }
}
