/**
Search Template specific styles
Note: this is intentionally left somewhat minimal; most CSS
is split into independent SCSS files (ie, search-results).
This file contains primarily structure  SCSS for those items
that doesn't necessarily warrant its own file, eg no results found messaging.
 */

#search {

    /**
    Default, Mobile first styles
     */

    .no-results,
    .no-search-term {
        @include mobileContentPadding();
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
