/**
Documentation
 */

.search-results {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 50px;
    margin-bottom: 59px;

    .summary {
        @include font(18px,31px);
        color: map_get($colours, 'n-4');
        margin-bottom: 41px;
    }

    .result-item {
        padding-bottom: 45px;
        margin-bottom: 40px;
        color: map_get($colours, 'p-2');
        border-bottom: solid 1px map_get($colours, 'n-7');
    }

    .document-item {
        margin-bottom: 40px;
    }

    .item-title {
        @include font(22px, 25px);
        color: inherit;
        margin-bottom: 3px;
        font-weight: 700;
        text-decoration: none;

        &:hover {
            color: map_get($colours, 's-2');
            text-decoration: $link-hover-decoration;
        }
    }

    .item-url {
        @include font(16px, 26px);
        margin-bottom: 17px;
    }

    .item-desc {
        @include font(18px, 31px);
        margin-bottom: 0;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 87px;
        margin-bottom: 59px;


        .summary {
            margin-bottom: 54px;
        }

        .item-title {
            @include font(22px, 35px);
            margin-bottom: 9px;
        }
    }
}
