/**
Placeable Kentioco widget, displaying a series of office items with address details
 */

.office-listing {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 56px;
    margin-bottom: 20px;

    .title-block {
        margin-bottom: 30px;
        border-bottom: solid 4px map_get($colours, 'p-4');
        color: map_get($colours, 'p-2');
    }

    .widget-title {
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .widget-subtitle {
        margin-bottom: 35px;
    }

    /*
    Individual office items
     */
    .office-item {
        @include font(18px, 30px);
        margin-bottom: 45px;
        color: map_get($colours, 'p-2');

        p {
            margin-bottom: 0;
            font-size: inherit;
            line-height: inherit;
        }
    }

    .office-title {
        font-weight: 700;
    }

    //992 +
    @include media-breakpoint-up(lg) {

        margin-top: 90px;
        margin-bottom: 90px;

        .title-block {
            display: flex;
            align-items: flex-end;
            margin-bottom: 30px;
            padding-bottom: 36px;
            border-bottom: solid 4px map_get($colours, 'p-4');
        }

        .widget-title {
            margin-bottom: 0;
        }

        .widget-subtitle {
            margin-left: 10px;
            margin-bottom: 4px
        }


        /*
        Column + structure overrides
         */
        .listing-row {
            /*
            Wizardry to force the 4th item onto a new row.
            I've done this (rather than create a new .row element so as to avoid the
            C# logic of having to inject a row element after every 4th column.

            This isn't pretty, but works.
             */
            $fullColumnOffset: calc( #{(percentage(1 / 12))} + #{$grid-gutter-width} );
            padding-left: $grid-gutter-width / 2;
            padding-right: $fullColumnOffset;
        }

        /*
        Individual office items
         */
        .office-item {
            margin-bottom: 50px;

            .office-title {
                @include font(22px, 35px);
            }
        }
    }
}
