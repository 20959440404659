/*
Bootstrap variable configuration and overrides
 */
$font-family-base: $fontPrimary;
$body-bg: #ffffff;
$body-color: #111;

$link-color: map_get($colours, "s-2");
$link-hover-color: map_get($colours, "n-4");
$link-decoration: underline;
$link-hover-decoration: underline;

$grid-gutter-width: 24px;