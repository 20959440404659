/**
Catch All for shared styles across performance items
Examples: margin / padding, title styles etc
 */

.performance-block {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin: 40px 0;

    .item-title {
        margin-bottom: 30px;
        color: map_get($colours, 'p-2');
        text-transform: uppercase;
    }

    .table-responsive-wrap {
        position: relative;

        .preloader {
            margin: 40px auto;
        }
    }
    
    .btn-download {
        margin-top: 30px;
        margin-left: auto;
        min-width: 280px;
        float: right;
    }
    
    .extra-info { 
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 30px;
        
        .btn-download {
            margin-left: initial;
            margin-top: 0;
            float: none;
        }
        
    }
    
    .investment-returns-disclaimer {
        
    }

    .disclaimer-text p {
        @include font(16px, 26px);
        color: #666;
    }

    

    .unit-price-table {
        
    }
    

    //992 +
    @include media-breakpoint-up(lg) {
        margin: 60px 0;

        .unit-price-table {
            position: relative;

            .btn-download {
                position: absolute;
                bottom: 35px;
                right: 0;
                margin-top: 0;
                margin-left: initial;
                float: none;
                min-width: 200px;
            }
        }
    }
}
