/**
Three column text widget capturing investment summary / overview
 */

.investment-summary {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 30px;
    margin-bottom: 30px;
    color: map_get($colours, 'p-2');

    .title {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    p {
        @include font(20px, 30px);
        margin-bottom: 25px;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 55px;
        margin-bottom: 100px;

        .title {
            margin-bottom: 18px;
        }

        p {
            @include font(26px, 39px);
            margin-bottom: 0;
        }
    }
}
