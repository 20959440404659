/**
Accordion module
Consists primarily of a button element, and a content block
 */

.accordion {
    /*
    Module specific variables
     */
    $itemBorderColour: map_get($colours, 'n-8');
    $btnTxtColour: map_get($colours, 'n-2');
    $btnTxtColour_hover: map_get($colours, 'p-2');
    $btnTxtColour_active: map_get($colours, 'n-2');


    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    width: 100%;
    margin-top: 56px;
    margin-bottom: 65px;

    .widget-title {
        color: map_get($colours, 'p-2');
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .accordion-items-wrapper {
        border-top: solid 1px $itemBorderColour;
    };

    .accordion-item {
        width: 100%;
        padding: 29px 0 21px 0;
        position: relative;
        border-bottom: solid 1px $itemBorderColour;
    }

    .btn-accordion-trigger {
        @include btnReset();
        display: flex;
        width: 100%;
        color: $btnTxtColour;
        text-align: left;

        .title-text {
            @include font(18px, 30px);
            margin: 0;
            padding: 0;
            padding-right: 20px;
            font-weight: 700;
        }

        .icon {
            margin-left: auto;
            align-self: center;
            opacity: 0.3;
        }

        .icon.active {
            display: none;
        }

        &:hover {
            color: $btnTxtColour_hover;

            .icon {
                opacity: 1;
            }
        }
    }

    .accordion-content {
        @include font(16px, 26px);
        color: map_get($colours, 'n-4');
        overflow: hidden;
        height: 0;

        .inner-content {
            padding: 27px 0 25px 0;
        }

        p, a, ul, ol, li {
            font-size: inherit;
            line-height: inherit;
        }
    }

    /*
    Open accordion state
     */
    .accordion-item.active {

        .btn-accordion-trigger {
            color: $btnTxtColour_active;

            .icon.default {
                display: none;
            }

            .icon.active {
                display: block;
                opacity: 1;
            }

            &:hover {
                color: $btnTxtColour_hover
            }
        }

        .accordion-content {
            height: auto;
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {

        margin-top: 90px;
        margin-bottom: 110px;

        .widget-title {
            margin-bottom: 58px;
        }

        .accordion-content {
            .inner-content {
                padding: 27px 40px 8px 0;
            }
        }
    }
}
