/**
Widget with title and description text and CTA button
 */

.promo-cta {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    padding-top: 57px;
    padding-bottom: 65px;
    margin-top: 0;
    margin-bottom: 0;
    //background-color: map_get($colours, 'p-1');
    color: map_get($colours, 'p-2');

    .widget-title {
        @include font(28px, 36px);
        font-family: $fontSecondary;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 12px;
    }

    .desc {
        margin-bottom: 28px;
    }

    a {
        

        &:hover {
            color: map_get($colours, 'p-2');
            background-color: white;
        }
    }

    .center-text {
        text-align: center;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        padding-top: 37px;
        padding-bottom: 39px;

        .widget-title {
            @include font(34px, 40px);
            margin-bottom: 15px;
        }

        .desc {
            margin-bottom: 0;
        }

        a {
            width: auto;
            margin-top: 39px;
        }

        .stack-right {
            float: right;
        }
    }
}
