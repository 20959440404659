/**
Documentation
 */

.image-header {
    margin-top: 0px !important;
    /*
    Module specific variables
     */
    $itemBorderColour: map_get($colours, "n-4");

    /**
    Default, Mobile first styles
     */
    color: map_get($colours, 'p-2');
    position: relative;
    
    /*
    Image column + image elems
     */
    img,
    picture {
        display: block;

    }
    /*
    Text column and Content styling
     */
    .content-col {
        padding-top: 50px;
        padding-bottom: 30px;
    }

    .date {
        @include font(14px, 23px);
        margin-bottom: 6px;
        color: map_get($colours, 'n-3');
    }

    .title {
        @include font(32px, 38px);
        text-transform: uppercase;
        margin-bottom: 19px;
        color: inherit;
    }

    .desc {
        @include font(18px, 31px);
        margin-bottom: 28px;
        color: map_get($colours, 'n-3');

        p {
            font-size: inherit;
            line-height: inherit;
            margin-bottom: 0;
        }
    }



    .btn {
        width: 100%;
        /*
        Because lets break all our design foundations
         */
        &:hover {
            background-color: map_get($colours, 'p-2');
            border-color: map_get($colours, 'p-2');
            color: white;
        }
    }

    .link {
        display: inline;
        padding-right: 16px;
        margin-top: 50px;
        margin-bottom: 20px;

        .btn-first--small {
            @include btnPill( $padding: 8px 20px 8px 20px, $colour_default: map_get($colours, 'n-10'), $colour_hover: map_get($colours, 'p-2'), $borderColour_default: map_get($colours, 'p-2'), $borderColour_hover: map_get($colours, 'n-10'), $bgColour: map_get($colours, 'p-2'), $bgColour_hover: map_get($colours, 'n-10') );

            &:active {
                @include btnPill( $padding: 8px 20px 8px 20px, $colour_default: map_get($colours, 'p-2'), $colour_hover: map_get($colours, 'p-2'), $borderColour_default: map_get($colours, 'n-10'), $bgColour: map_get($colours, 'n-10') );
            }

            
        }

        .btn-secondary--small {
            &:active {
                @include btnPill( $padding: 8px 20px 8px 20px, $colour_default: map_get($colours, 'n-10'), $colour_hover: map_get($colours, 'n-10'), $borderColour_hover: map_get($colours, 'p-2'), $bgColour: map_get($colours, 'p-2'), $bgColour_hover: map_get($colours, 'p-2') );
            }
        }
    }

    /*
    Structual + grid overrides
     */
     @include media-breakpoint-down(md) {
        > .container-fluid {
            padding-left: 0;
            padding-right: 0;
        }

        .content-col {
            padding: $grid-gutter-width;
        }

        .link {
            margin-top: 35px;
            margin-bottom: 0;

            .btn-first--small {
                width: 100%;
                margin-bottom: 16px;
            }
        }

        img,
        picture {
            max-width: 80%;
            margin: 0 auto;
        }    
    }

    //992 +
    @include media-breakpoint-up(lg) {
        //Default Height
        $defaultHeight: 370px;
        $heroHeight: 600px;
        $heroWithLinksHeight: 750px;

        height: $defaultHeight;

        .container-fluid {
            height: 100%;

            .row {
                height: 100%;
            }
        }
        /*
        Image column + image elems
         */
        .img-col {
            height: $defaultHeight;
            display: flex;
            justify-content: flex-end;
            position: absolute;
            overflow: hidden;
            right: 0;
            top: 0;
            padding-left: $grid-gutter-width / 2;
        }

        img,
        picture {
            display: block;
            width: 90%;
            object-fit: cover;
            object-position: right bottom;
            margin-left: auto;
        }

        .date {
            @include font(14px, 23px);
            margin-bottom: 2px;
        }

        .title {
            @include font(60px, 65px);
            margin-bottom: 25px;
        }

        .btn {
            width: auto;
            min-width: 180px;
        }
        /*
        Larger, hero varient
         */
        &.image-header-hero {
            height: $heroHeight;

            .img-col {
                height: $heroHeight;
            }

            img,
            picture {
                width: 100%;
                object-position: left bottom;
                margin-left: 0;
            }
        }
        /*
        With links version - used for error pages primarily
         */
        &.image-header-hero-with-links {
            height: $heroWithLinksHeight;
            padding: 100px 0;

            .img-col {
                height: $heroWithLinksHeight;
            }

            img,
            picture {
                width: 100%;
                object-position: left bottom;
                margin-left: 0;
            }
        }
        /*
        Animated version
         */
        &[data-onload-animation='true'] {
            //Default state
            .img-block {
                @include animateCustom(#{'transform, opacity'}, 2000ms );
                transform: translateX(40px);
                opacity: 0;
            }

            .content-col {
                .title,
                .desc,
                .link {
                    transform: translateY(20px);
                    opacity: 0;
                }

                .title {
                    @include animateCustom(#{'transform, opacity'}, 750ms )
                }

                ;

                .desc {
                    @include animateCustom(#{'transform, opacity'}, 750ms, 300ms )
                }

                ;

                .link {
                    @include animateCustom(#{'transform, opacity'}, 750ms, 600ms )
                }

                ;
            }
            //DOM loaded, animate 'end' state
            &[data-animated='true'] {

                .img-block {
                    transform: translateX(0);
                    opacity: 1;
                }

                .content-col {
                    .title,
                    .desc,
                    .link {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}