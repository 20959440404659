$maxContentWidth: 1344px;

/*
Common config variables
These are deliberately on the global scope as they
are shared between _global-header and _global-nav
 */

$globalHeader: (
    "moduleHeight": 80px,
    "moduleHeight_lg": 125px,
);

$notificationBanner: (
    "moduleHeight": 140px,
    "moduleHeight_lg": 80px,

);

$utilityNav: (
    "moduleHeight_lg": 35px,
);