$fontPrimary: 'Poppins', sans-serif;
$fontSecondary: 'Londrina Solid', arial, sans-serif;

$browser-context: 16px;
$body: (
        "fontSize": 16px,
        "lineHeight": 26px,
        "fontSize_lg": 16px,
        "lineHeight_lg": 26px,
);

$h1: (
        "fontSize": 32px,
        "lineHeight": 38px,
        "fontSize_lg": 48px,
        "lineHeight_lg": 56px,
);

$h2: (
        "fontSize": 28px,
        "lineHeight": 36px,
        "fontSize_lg": 38px,
        "lineHeight_lg": 46px,
);

$h3: (
        "fontSize": 26px,
        "lineHeight": 34px,
        "fontSize_lg": 34px,
        "lineHeight_lg": 40px,
);

$h4: (
        "fontSize": 24px,
        "lineHeight": 34px,
        "fontSize_lg": 28px,
        "lineHeight_lg": 38px,
);

$h5: (
        "fontSize": 20px,
        "lineHeight": 27px,
        "fontSize_lg": 24px,
        "lineHeight_lg": 34px,
);

$h6: (
        "fontSize": 20px,
        "lineHeight": 27px,
        "fontSize_lg": 20px,
        "lineHeight_lg": 30px,
)