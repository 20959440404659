/**
Documentation
 */

.promo-tile-row {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 55px;
    margin-bottom: 60px;

    /*
    Structural
     */
    .promo-tile--small,
    .promo-tile--medium,
    .promo-tile--large {
        height: 100%;
    }

    /*
    Title and link
    Note link is optional in CMS, and two different DOM elements will be
    required (one for mobile, one for desktop);
     */
    .title-block {
        margin-bottom: 34px;
        border-bottom: solid 4px map_get($colours, 'p-4');

        .view-more {
            display: none;
        }
    }

    .widget-title {
        @include font(32px, 38px);
        margin-bottom: 31px;
        font-weight: 400;
        text-transform: uppercase;
        color: map_get($colours, 'p-2');
    }

    /*
    Mobile only button
     */
    .btn-secondary--small {
        width: 100%;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 87px;

        .title-block {
            position: relative;
            margin-bottom: 47px;
            border-bottom: solid 6px map_get($colours, 'p-4');

            .view-more {
                position: absolute;
                right: 0;
                bottom: -16px;
                padding-left: 40px;
                display: block;
                background-color: white;
            }
        }

        .widget-title {
            @include font(48px, 56px);
            margin-bottom: 34px;
        }
    }
}
