/**
Default Form Elements and inputs
 */
label {
    @include font(16px, 26px);
    margin-bottom: 5px;
    font-weight: 700;
    color: map_get($colours, 'n-2');
}

input[type='text'],
input[type='email'],
input[type='select'],
textarea,
select {
    @include font(16px, 26px);
    width: 100%;
    padding: 12px 16px 11px 16px;
    border: solid 1px map_get($colours, 'n-7');
    border-radius: 4px;
    background-color: map_get($colours, 'n-10');

    &:active {
        border-color: map_get($colours, 'n-2');
    }
}

textarea {
    min-height: 160px;
}

/*
Custom checkboxes
 */
input[type='checkbox'] {
    /*
    Scoped Checkboxvariables
     */
    $checkBoxWidth: 24px;
    $checkBoxBackgroundColour--default: map_get($colours, 'n-10');
    $checkBoxBackgroundColour--hover: map_get($colours, 'n-10');
    $checkBoxBackgroundColour--checked: map_get($colours, 'n-10');

    $checkBoxBorderWidth: 1px;
    $checkBoxBorderRadius: 3px;
    $checkBoxBorderColour--default: map_get($colours, 'n-7');
    $checkBoxBorderColour--hover: map_get($colours, 'n-7');
    $checkBoxBorderColour--checked: map_get($colours, 'n-7');

    $checkBoxtickColour: map_get($colours, 'p-4');
    $checkBoxtickPosition_x: 7px;
    $checkBoxtickPosition_y: 12px;

    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }
    // Box.
    & + label:before {
        content: '';
        display: inline-block;
        position: relative;
        top: -2px;
        width: $checkBoxWidth;
        height: $checkBoxWidth;
        margin-right: 10px;
        vertical-align: middle;
        border: solid $checkBoxBorderWidth $checkBoxBorderColour--default;
        background: $checkBoxBackgroundColour--default;
        border-radius: $checkBoxBorderRadius;
    }
    // Box hover + focus
    &:hover + label:before,
    &:focus + label:before {
        background: $checkBoxBackgroundColour--hover;
        border-color: $checkBoxBorderColour--hover;
    }
    // Box checked
    &:checked + label:before {
        background: $checkBoxBackgroundColour--checked;
        border-color: $checkBoxBorderColour--checked;
    }
    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }
    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: $checkBoxtickPosition_x;
        top: $checkBoxtickPosition_y;
        background: $checkBoxtickColour;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $checkBoxtickColour, 4px 0 0 $checkBoxtickColour, 4px -2px 0 $checkBoxtickColour, 4px -4px 0 $checkBoxtickColour, 4px -6px 0 $checkBoxtickColour, 4px -8px 0 $checkBoxtickColour;
        transform: rotate(35deg);
    }
}

/*
Custom Radios
 */
input[type='radio'] {
    /*
    Scoped Radio variables
     */
    $radioWidth: 24px;
    $radioBackgroundColour--default: map_get($colours, 'n-10');
    $radioBackgroundColour--hover: map_get($colours, 'n-10');
    $radioBackgroundColour--checked: map_get($colours, 'n-10');

    $radioBorderWidth: 2px;
    $radioBorderRadius: 50%;
    $radioBorderColour--default: map_get($colours, 'n-7');
    $radioBorderColour--hover: map_get($colours, 'n-7');
    $radioBorderColour--checked: map_get($colours, 'n-7');

    $radioTickColour: map_get($colours, 'p-4');;
    $radioTickPosition_x: 5px;
    $radioTickPosition_y: 5px;
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        display: inline-block;
    }

    // Box.
    & + label:before {
        content: '';
        display: inline-block;
        width: $radioWidth;
        height: $radioWidth;
        position: relative;
        top: -2px;
        margin-right: 10px;
        vertical-align: middle;
        border: solid $radioBorderWidth $radioBorderColour--default;
        border-radius: $radioBorderRadius;
        background: $radioBackgroundColour--default;
    }

    // Box hover + focus
    &:hover + label:before,
    &:focus + label:before {
        background: $radioBackgroundColour--hover;
        border-color: $radioBorderColour--hover;
    }

    // Box checked
    &:checked + label:before {
        background: $radioBackgroundColour--checked;
        border-color: $radioBorderColour--checked;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: $radioTickPosition_x;
        top: $radioTickPosition_y;
        background: $radioTickColour;
        width: $radioWidth - 10px;
        height: $radioWidth - 10px;
        border-radius: $radioBorderRadius;
    }
}

/*
Submit Button
 */
input[type='submit']{
    @include btnReset();
    @include btnPill(
        $padding: 7px 20px 8px 20px,
        $fontSize: 18px,
        $lineHeight: 30px,

        $fontSize_lg: 16px,
        $lineHeight_lg: 26px
    );
}