/**
Documentation
 */


#global-header {
    /**
    Module specific variables
     */
    $moduleBgColor: white;

    /**
    Default, Mobile first styles
     */
    height: map_get($globalHeader, "moduleHeight");
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: $moduleBgColor;


    //Child rows + wrappers
    .utility-nav-wrapper,
    .primary-nav-wrapper {
        width: 100%;
    }

    .primary-nav-wrapper {
        padding: 18px 0 20px $grid-gutter-width / 2;
    }


    h1 {
        margin: 0;
        padding: 0;
    }

    .site-logo {
        width: 142px;
        display: block;
    }

    .mobile-controls {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }

    .btn-mobile-control {
        display: block;
        width: 42px;
        height: 42px;
        padding: 9px;
        color: map_get($colours, 'p-2');
    }

    .btn-offcanvas {
        @include btnReset();
        .icon { margin: auto;}
        .icon.active { display: none; }
        &.nav-open {
            .icon.default { display: none; }
            .icon.active { display: block; }
        }
    }

    //992 +
    @include media-breakpoint-up(lg) {
        width: 100%;
        padding: 0;
        height: map_get($globalHeader, "moduleHeight_lg");
        position: relative;

        .utility-nav-wrapper {
            height: map_get($utilityNav, "moduleHeight_lg");
            background-color: map_get($colours, 'p-2');
        }

        .primary-nav-wrapper {
            height: 90px;
            padding: 0;
        }

        .site-logo {
            padding-top: 11px;
            width: 198px;
            height: 70px;
        }
    }
}
