


/**
Documentation
 */

.modal {

    /**
    Default, Mobile first styles
     */
    color: map_get($colours, 'p-2');

    .modal-overlay {
        display: flex;
        z-index: 200;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(black, 0.6);
    }

    .modal-container {
        position: relative;
        width: 80%;
        max-width: 870px;
        max-height: 100vh;
        padding: 45px;
        background-color: map_get($colours, 'n-10');
        border: 1px solid map_get($colours, 'n-9');
        border-radius: 5px;
    }

    .modal-title {
        text-align: center;
    }

    .modal-content {
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;
    }

    .modal-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            display: block;

            &:first-child {
                margin-bottom: 20px;
            }

            &:focus-visible {
                outline: 0;
            }
        }

        .btn-default {
            min-width: 300px;
        }
    }

    .btn-close {
        @include btnReset();
        position: absolute;
        top: 5px;
        right: 5px;
        width: 40px;
        height: 40px;
    }

    @include media-breakpoint-up(xs) {
    }

    //576 +
    @include media-breakpoint-up(sm) {
    }

    //768 +
    @include media-breakpoint-up(md) {
    }

    //992 +
    @include media-breakpoint-up(lg) {
    }

    //1200 +
    @include media-breakpoint-up(xl) {
    }
}






























/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes mmslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.modal {
    display: none;
}

.modal.is-open {
    display: block;
}

$animationSpeed: 250ms;

.modal[aria-hidden="false"] .modal-overlay {
    animation: mmfadeIn $animationSpeed cubic-bezier(0.0, 0.0, 0.2, 1);
}

.modal[aria-hidden="false"] .modal-container {
    animation: mmslideIn $animationSpeed cubic-bezier(0, 0, .2, 1);
}

.modal[aria-hidden="true"] .modal-overlay {
    animation: mmfadeOut $animationSpeed cubic-bezier(0.0, 0.0, 0.2, 1);
}

.modal[aria-hidden="true"] .modal-container {
    animation: mmslideOut $animationSpeed cubic-bezier(0, 0, .2, 1);
}

.modal .modal-container,
.modal .modal-overlay {
    will-change: transform;
}
















