
/*
Asset Allocation variables
These are created based off legacy inline styles used in
asset allocation tables.
I've done the best I can.
 */
//Known values as covered in design
$australianEquities: map_get($colours, 'p-1');
$internationalEquities: map_get($colours, 'p-3');
$internationalListedProperty: map_get($colours, 'p-6');
$australianDirectProperty: map_get($colours, 's-4');
$australianFixedInterest: map_get($colours, 's-4');
$cash: map_get($colours, 'n-8');
$bonds: map_get($colours, 's-3');
$privateEquity: map_get($colours, 'p-5');
$privateEquityAndOpportunisticAlternatives: map_get($colours, 'p-6');
$infrastructure: map_get($colours, 'n-5');
$shortTermFixedInterest: map_get($colours, 's-1');
$liquidAlternatives: map_get($colours, 'n-9');
$growthAlternatives: map_get($colours, 'n-4');

/*
Unknown values - NOT included in design and no appropriate fallback
As such, I've arbitrarily derived a number of fallbacks
 */
$AAfallBackOne: map_get($colours, 'p-5');
$AAfallBackTwo: map_get($colours, 'p-3');
$AAfallBackThree: map_get($colours, 's-5');

$privateEquityOpportunisticAlternatives: $AAfallBackOne;
$commodities: $AAfallBackTwo;
$australianInflationLinkedBonds: $AAfallBackThree;
$internationalFixedInterest: $AAfallBackOne;
$absoluteReturnFunds: $AAfallBackTwo;
$defensiveAlternatives: $AAfallBackThree;
$sustainableAustralianShares: $AAfallBackOne;
$defensiveLiquids: $AAfallBackTwo;
$private: $AAfallBackThree;
$defensiveLiquids: $AAfallBackOne;
$opportunisticAlternatives: $AAfallBackTwo;

.asset-allocation-table {
    /*
   Indicator element used in asset allocation tables
   NOTE: this markup is currently copy / pasted from legacy site
   Hence, CSS is copied over also
    */
    .alloBox {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 15px;
    }

    /*
    Legacy styling of 'alloBox' element.
    I'm sorry.
     */

    .alloBox.Australian.Equities{background-color:$australianEquities;}
    .alloBox.International.Equities{background-color:$internationalEquities;}
    .alloBox.International.Listed.Property{background-color:$internationalListedProperty;}
    .alloBox.Australian.Direct.Property{background-color: $australianDirectProperty;}

    /*
    NOTE: I've removed a selector here - original was:
    .alloBox.Private.Equity.&.Opportunistic.Alternatives
    This was breaking SASS build and is just silly. We don't need it.
     */
    .alloBox.Private.Equity.Opportunistic.Alternatives{background-color:$privateEquityOpportunisticAlternatives;}

    .alloBox.Commodities{background-color:$commodities;}
    .alloBox.Australian.Fixed.Interest{background-color:$australianFixedInterest;}
    .alloBox.Australian.Inflation.Linked.Bonds{background-color:$australianInflationLinkedBonds;}
    .alloBox.International.Fixed.Interest{background-color:$internationalFixedInterest;}
    .alloBox.Absolute.Return.Funds{background-color:$absoluteReturnFunds;}
    .alloBox.Defensive.Alternatives{background-color:$defensiveAlternatives;}
    .alloBox.Cash{background-color:$cash;}
    .alloBox.Sustainable.Australian.Shares{background-color:$sustainableAustralianShares;}
    .alloBox.Defensive.Iiquids{background-color:$defensiveLiquids;} //Typo in 'liquids' is intentional AND required
    .alloBox.Private{background-color:$private;}
    .alloBox.Defensive.Illiquids{background-color:$defensiveLiquids;} //Typo in 'liquids' is intentional AND required
    .alloBox.Bonds{background-color:$bonds;}
    .alloBox.Private.Equity{background-color:$privateEquity;}
    .alloBox.Opportunistic.Alternatives{background-color:$opportunisticAlternatives;}
    .alloBox.Private.Equity.and.Opportunistic.Alternatives{background-color:$privateEquityAndOpportunisticAlternatives;}
    .alloBox.Infrastructure{background-color:$infrastructure;}
    .alloBox.Short.Term.Fixed.Interest{background-color:$shortTermFixedInterest;}
    .alloBox.Liquid.Alternatives{background-color:$liquidAlternatives;}
    .alloBox.Growth.Alternatives{background-color:$growthAlternatives;}
}