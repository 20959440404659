/**
Marketo Form Widget
 */

.marketo-form {
    margin-top: 56px;
    margin-bottom: 56px;

    //768 +
    @include media-breakpoint-up(md) {
        margin-top: 100px;
        margin-bottom: 100px;
    }
}