/**
Documentation
 */

.subnav-group {
    /**
    Default, Mobile first styles
     */
    ul {
        li {
            margin-bottom: 25px;
        }

        a {
            @include font(16px, 20px);
            color: map_get($colours, 'p-2');
            font-weight: 400;
            text-decoration: none;
            display: block;
        }

        li:last-child a {
            margin-bottom: 15px;
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {
        position: relative;

        .sub-nav {
            width: 455px;
            padding: 0 77px 25px 66px;
            position: absolute;
            right: 0;
        }

        a {
            @include animateCustom(#{'color'}, 250ms );
            &:hover {
                font-weight: 700;
                color: map_get($colours, 'p-4');
            }
        }
    }
}
