/**
Documentation
 */

#notification-bar {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    width: 100%;
    background-color: map_get($colours, 's-3');
    color: map_get($colours, 'p-2');
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    display: none; //Hidden by default, based on cookie check
    height: map_get($notificationBanner, 'moduleHeight');

    .content-wrap {
        position: relative;
        padding-right: 30px;
    }

    .notification-title {
        @include fontSize(18px);
        @include lineHeight(25px);
        margin: 0;
        font-weight: 700;
    }

    .notification-text {
        @include fontSize(16px);
        @include lineHeight(26px);
        margin: 0;

        a {
            font-weight: 700;
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            text-decoration: underline;
        }
    }

    .btn-close {
        @include btnReset();
        position: absolute;
        top: 8px;
        right: -8px;
        width: 30px;
        height: 30px;

        .icon {
            width: 28px;
            height: 28px;
            color: map_get($colours, 'n-10');
        }
    }

    &.active {
        display: block;
    }


    //992 +
    @include media-breakpoint-up(lg) {
        height: map_get($notificationBanner, 'moduleHeight_lg');
        position: relative;

        .btn-close {
            right: 0;
        }
    }
}
