/**
Content Slider with three (or more) featured content items
 */

.featured-content-slider {
    /**
    Default, Mobile first styles
     */
    padding-top: 56px;
    padding-bottom: 63px;
    margin-top: 65px;
    margin-bottom: 65px;
    background-color: map_get($colours, 'n-9');

    .intro-content {
        @include mobileContentPadding();
    }

    .widget-title {
        color: map_get($colours , 'p-4');
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .widget-desc {
        @include font(18px, 31px);
        color: map_get($colours , 'p-2');
        margin-bottom: 29px;
    }

    .swiper-parent {
        padding-left: $grid-gutter-width;
    }
    /*
    Any required swiper overrides + control positioning
     */
    .swiper-container {
        padding-right: 75px;
    }

    .btn-swiper-prev,
    .btn-swiper-next {
        position: absolute;
        top: 45%;
        margin-top: -32px; //Half height
        z-index: 10;
    }

    .btn-swiper-prev {
        left: 0;
    }

    .btn-swiper-next {
        right: 0;
    }

    /*
    Featured item slides
     */
    .swiper-slide {
        padding-bottom: 30px;
        border-bottom: solid 1px map_get($colours, 'p-4');
    }

    .featured-item {
        position: relative;
        width: 100%;
        height: 517px;
    }

    .img-block {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top left;
        }
    }

    .content-block {
        display: flex;
        align-items: flex-end;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 30px $grid-gutter-width;
        color: white;
        background-color: rgba(black, 0.4);
    }

    .item-title {
        @include font(32px, 38px);
        max-width: 50%;
        margin-bottom: 25px;
        font-family: $fontSecondary;
        text-transform: uppercase;
    }


    //768 +
    @include media-breakpoint-up(md) {
        padding-top: 88px;
        padding-bottom: 100px;
        margin-top: 98px;
        margin-bottom: 100px;

        .swiper-parent {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width / 2;
            margin: auto;
        }

        /*
        Any required swiper overrides + control positioning
         */
        .swiper-container {
            padding-right: 0;
        }

        .btn-swiper-prev {
            left: 0;

            &:active {
                left: -2px;
            }
        }

        .btn-swiper-next {
            right: 0;

            &:active {
                right: -2px;
            }
        }

        /*
        Featured item slides
        */
        .swiper-slide {
            padding-bottom: 40px;
        }

        .img-block {
            @include animateCustom(#{'transform'}, 450ms );
        }

        .featured-item {
            height: 517px;
            overflow: hidden;

            &:hover {
                .img-block {
                    transform: scale(1.1)
                }
            }
        }

        .item-title {
            @include font(48px, 46px);
            max-width: 80%;
            margin-bottom: 17px;
        }
    }
}
