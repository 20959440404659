/**
Single image with optional text description
 */

.single-image-widget {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 37px;
    margin-bottom: 37px;

    .img-block,
    picture,
    img {
        width: 100%;
    }

    .img-caption {
        padding-top: 7px;
        border-bottom: solid 1px map_get($colours, 'n-8');
    }

    p {
        @include font(16px, 26px);
        color: map_get($colours, 'n-5');
        margin-bottom: 10px;
    }
    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 92px;
        margin-bottom: 44px;

        p {
            @include font(18px, 31px);
            margin-bottom: 10px;
        }
    }
}
