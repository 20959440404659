/**
Image with text module
Consists of text and an image
 */

.image-with-text {

    .container-responsive-wrap {
        display: flex;

        
    }

    .imageLeftAligned {
        flex-direction: row-reverse;
    }

    @media screen and (max-width: 768px) {
        .container-responsive-wrap {
          flex-direction: column-reverse;
        }
      
        .container-responsive-wrap img {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
}