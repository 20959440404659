/**
Documentation
 */

.wayfinding-semantic-search {
    @include mobileContentPadding();
    margin-top: 49px;
    margin-bottom: 56px;
    position: relative;
    z-index: 10;

    .inner-wrap {
        background: white;
    }

    .title {
        @include font(22px, 25px);
        font-weight: 700;
        color: map_get($colours, 'p-2');
        margin-bottom: 16px;
    }

    // choices class is JS injected custom dropdown wrapper
    .choices,
    select {
        margin-bottom: 43px;
    }

    .btn-submit {
        width: 100%;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        $fullColumnOffset: calc( #{(percentage(1 / 12))} - #{$grid-gutter-width} );
        margin-bottom: 26px;
        margin-left: auto;
        margin-right: auto;

        .col-12 {
            max-width: 1168px;
            padding: 0;
            margin: auto;
        }

        .inner-wrap {
            background: white;
            position: relative;
            padding-top: 59px;
            padding-bottom: 51px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: $fullColumnOffset;
            padding-right: $fullColumnOffset;
        }

        .flex-child-wrap {
            padding: 0 $grid-gutter-width / 2;
        }

        .title {
            justify-content: flex-end ;
            margin-bottom: 0;
        }

        .choices,
        select {
            margin-bottom: 0;
        }

        .btn-submit {
            width: 200px;
        }
    }
}

/*
Specific widget combinations require offset
 */
.image-header + .wayfinding-semantic-search {
    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 0;

        .inner-wrap {
            $verticalOffset: 122px;
            top: -$verticalOffset;
            margin-bottom: -$verticalOffset;
        }

        /*
       Animated version
        */
        &[data-onload-animation='true']{

            //Default state
            @include animateCustom(#{'transform, opacity'}, 1000ms, 600ms );
            transform: translateY(20px);
            opacity: 0;


            .flex-child-wrap {
                @include animateCustom(#{'transform, opacity'}, 1000ms, 800ms );
                transform: translateY(20px);
                opacity: 0;
            }


            //DOM loaded, animate 'end' state
            &[data-animated='true']{
                transform: translateY(0);
                opacity: 1;

                .flex-child-wrap {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }
}