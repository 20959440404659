/**
NOTE:
Below styles are vendor specific for the choices custom
select lib.

https://github.com/Choices-js/Choices
 */

.choices__inner {

    @include font(16px, 26px);
    width: 100%;
    min-width: 312px;
    height: 50px;
    padding: 12px 16px 11px 16px;
    border: solid 1px map_get($colours, 'n-2');
    border-radius: 4px;
    background-color: map_get($colours, 'n-10');

    .is-open &,
    .is-focused & {
        border-color:  map_get($colours, 'n-2');
    }

    &.is-open {
        border-radius: 4px 4px 0 0;
    }
}

.choices__list--single {
    padding: 0 20px 0 0;
}

.choices__list--dropdown {
    @include font(16px, 26px);
    padding: 0;
    border-style: solid;
    border-color: map_get($colours, 'n-2');
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 4px 4px;
    z-index: 10;

    .choices__list {
        .choices__item {
            @include font(16px, 26px);
        }

        .choices__item--selectable {
            padding: 12px 15px;
            padding-right: 0;

            &.is-highlighted {
                background: transparent;
            }

            &:hover {
                color: map_get($colours, 'p-4');
            }
        }
    }


    .is-open &,
    .is-focused & {
        border-color:  map_get($colours, 'n-2');
    }
}

//Fake arrow
.choices[data-type*='select-one']:after {
    border-color: map_get($colours, 'n-2') transparent transparent transparent;
}