/**
Pagination List for search results
 */

.search-pagination {
    /**
    Default, Mobile first styles
     */
    margin-top: 32px;
    margin-bottom: 59px;

    ul {
        @include list-unstyled();
        display: flex;
        justify-content: center;
    }

    li {
        @include font(16px, 26px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        margin: 0 4px;
        color: map_get($colours, 'n-2');
        font-weight: 400;
        border: solid 2px transparent;
        border-radius: 3px;

        a {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit;
            text-decoration: none;

            &:hover {
                color: map_get($colours, 'p-4');
            }
        }

        &.active {
            border-color: map_get($colours, 'p-4');
        }
    }

    .previous,
    .next {
        color: map_get($colours, 'n-4');
        .icon {
            position: relative;
            top: -1px;
        }
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 46px;
        margin-bottom: 99px;
    }
}
