/**
Two Column placeable Kentico Widget
Primary 'featured' item in left column,
3x other items in right column, stacked vertically
 */

.featured-news {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 62px;
    margin-bottom: 0;
    padding-top: 57px;
    padding-bottom: 60px;
    background-color: map_get($colours, 'n-9');

    .section-title {
        text-transform: uppercase;
        color: map_get($colours, 'p-4');
        margin-bottom: 39px;
    }


    /*
    Shared item styles
    In case of overides, the 'non' featured styles are the default
     */
    .news-item {
        color: map_get($colours, 'p-2');
        margin-bottom: 28px;

        &:last-child { margin-bottom: 0;}
    }

    .img-block,
    img {
        width: 100%;
    }

    img {
        display: none;
    }

    .item-title {
        @include font(24px, 34px);
        font-family: $fontSecondary;
        text-transform: uppercase;
        margin-bottom: 9px;
    }

    a {
        display: inline-block;
        margin-bottom: 12px;
    }

    .date-block {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 100%;

        /*
        Faux border - can't use actual border because
        date text needs to overlap it
        */
        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            z-index: 1;
            left: 0;
            bottom: 8px;
            background-color: map_get($colours, 'p-2');
        }
    }

    .item-date {
        @include font(16px, 26px);
        position: relative;
        z-index: 5;
        margin: 0;
        padding-left: 5px;
        background-color: map_get($colours, 'n-9');
    }


    /*
    First 'featured' item
     */
    .news-item.featured {
        img {
            display: block;
            margin-bottom: 26px;
        }

        .item-title {
            @include font(32px, 38px);
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 105px;
        padding-top: 88px;
        padding-bottom: 96px;


        /*
        Structural
         */
        .listing-col {
            display: flex;
            flex-direction: column;
        }

        /*
        Shared item styles
        In case of overides, the 'non' featured styles are the default
         */
        .news-item {
            margin-bottom: 12px; //TODO -- check this
        }

        a {
            @include font(16px, 24px);
        }

        /*
        Default, NOT featured items
         */
        .news-item:not(.featured) {
            &:first-child {
                border-top: solid 6px map_get($colours, 'p-2');
                padding-top: 20px;
            }

            &:last-child {
                margin-bottom: 4px;
            }

            .content-wrap {
                display: flex;
            }

            img {
                display: block;
                width: 100%;
            }

            .img-block {
                flex-basis: 40%;
                max-width: 200px;
            }

            .content-block {
                display: flex;
                flex-basis: 60%;
                flex-direction: column;
                padding-left: $grid-gutter-width;
            }

            a {
                margin-top: auto;
                margin-bottom: 0;
            }
        }

        /*
        First 'featured' item
         */
        .news-item.featured {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            height: 100%;

            img {
                margin-bottom: 35px;
            }

            .item-title {
                @include font(48px, 56px);
                display: inline;
            }

            a {
                margin-bottom: 0;
            }

            .date-block {
                margin-top: auto;
                padding-top: 15px;
            }

            .item-date {
                @include font(26px, 39px);
            }
        }
    }
}
