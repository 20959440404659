$colours: (
        //Primary
        "p-1": #CCF3D7, // Mint Green
        "p-2": #003822, // Eucalyptus
        "p-3": #9ADF2A, // Budgie
        "p-4": #51BB18, // Lorikeet
        "p-5": #FFE3D8, // Gum Blossom
        "p-6": #F18EA5, // Galah

        //Secondary
        "s-1": #FF634F, // Red
        "s-2": #36A5B3, // Teal
        "s-3": #F6B111, // Orange
        "s-4": #342DA4, // Purple
        "s-5": #38424D, // Grey

        //Neutral
        "n-1": #000,
        "n-2": #111111,
        "n-3": #333333,
        "n-4": #666666,
        "n-5": #999999,
        "n-6": #AAAAAA,
        "n-7": #CCCCCC,
        "n-8": #DDDDDD,
        "n-9": #F5F5F5,
        "n-10": #FFFFFF,

        //Disable states
        "d-1": #767676
);
