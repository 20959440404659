.btn-default {
    @include btnPill();
}

.btn-default--small {
    @include btnPill(
        $padding: 8px 20px 8px 20px
    );
}

.btn-alt {
    @include btnPill(
        $colour_default: white,
        $colour_hover: white,
        $borderColour_default: white,
        $borderColour_hover: map_get($colours, 'p-2'),
        $bgColour: black,
        $bgColour_hover: map_get($colours, 'p-2')
    );
}


.btn-alt--small {
    @include btnPill(
        $padding: 8px 20px 8px 20px,
        $colour_default: white,
        $colour_hover: white,
        $borderColour_default: white,
        $borderColour_hover: map_get($colours, 'p-2'),
        $bgColour: transparent,
        $bgColour_hover: map_get($colours, 'p-2')
    );
}



.btn-secondary {
    @include btnPill(
        $colour_default: map_get($colours, 'p-2'),
        $colour_hover: map_get($colours, 'p-2'),
        $borderColour_default: map_get($colours, 'p-2'),
        $borderColour_hover: map_get($colours, 'p-1'),
        $bgColour: transparent,
        $bgColour_hover: map_get($colours, 'p-1')
    );
}


.btn-secondary--small {    
    @include btnPill(
        $padding: 8px 20px 8px 20px,
        $colour_default: map_get($colours, 'p-2'),
        $colour_hover: map_get($colours, 'p-2'),
        $borderColour_default: map_get($colours, 'p-2'),
        $borderColour_hover: map_get($colours, 'p-1'),
        $bgColour: transparent,
        $bgColour_hover: map_get($colours, 'p-1')
    );
}


.btn-social {
    @include animateCustom(#{'color'}, 250ms );
    text-decoration: none;
    .icon {
        fill: currentColor;
        stroke: none;
        stroke-width: 0;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}

/*
Swiper Controls
Note: positioning is handled at a widget level
 */
.btn-swiper-prev,
.btn-swiper-next {
    @include animateCustom(#{'background-color'}, 250ms );
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 64px;
    cursor: pointer;
    color: white;

    .icon {
        position: relative;
        width: 46px;
        height: 64px;
    }

    &:hover {
        background-color: map_get($colours, 'p-4');
    }
}

.btn-swiper-prev {
    .icon {
        left: -2px;
    }
}

.btn-swiper-next {
    .icon {
        right: -2px;
    }
}




