/*
Handy reset to clear browser default styles
for <button> elements
*/
@mixin btnReset(){
    background: none;
    border: none;
    margin: 0;
    padding: 0;

    &:focus,
    &:active {
        outline: none;
    }
}

@mixin btnDisabled(){
    cursor: pointer;
    pointer-events: none;
}

/*
Common, re-usable mixins for button styles
Application of these should be managed via /ui/_buttons.scss
 */

@mixin btnPill(
    $display: inline-block,
    $font: $fontPrimary,

    $fontSize: 18px,
    $lineHeight: 30px,

    $fontSize_lg: 18px,
    $lineHeight_lg: 30px,

    $fontWeight: 700,

    $colour_default: white,
    $colour_hover: map_get($colours, 'p-2'),
    $textDecoration: none,

    $padding: 13px 20px 14px 20px,

    $borderRadius: 50px,
    $borderWidth: 2px,

    $borderColour_default: map_get($colours, 'p-2'),
    $borderColour_hover: map_get($colours, 'p-1'),

    $bgColour: map_get($colours, 'p-2'),
    $bgColour_hover: map_get($colours, 'p-1'),

    $animateHover: true,
    $animationDuration: $defaultAnimationTime
){
    @include fontSize($fontSize);
    @include lineHeight($lineHeight);
    display: $display;
    padding: $padding;
    font-family: $font;
    font-weight: $fontWeight;
    color: $colour_default;
    border: solid $borderWidth $borderColour_default;
    border-radius: $borderRadius;
    background-color: $bgColour;
    text-decoration: $textDecoration;
    text-align: center;

    @if ($animateHover == true) {
        @include animateCustom(#{'border, color, background-color'}, $animationDuration);
    }

    &:hover {
        color: $colour_hover;
        border: solid $borderWidth $borderColour_hover;
        background-color: $bgColour_hover;
        text-decoration: $textDecoration;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize($fontSize_lg);
        @include lineHeight($lineHeight_lg);
    }
}
