/**
Documentation
 */

.promo-tile--small {
    /**
    Default, Mobile first styles
     */
    //height: 100%;
    display: flex;
    flex-direction: column;


    img {
        margin-bottom: 28px;
    }

    .content-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border-bottom: solid 1px map_get($colours, 'n-7');
        padding-bottom: 29px;
        margin-bottom: 45px;
    }

    .title {
        @include font(24px, 30px);
        margin-bottom: 11px;
        font-family: $fontSecondary;
        color: map_get($colours, 'p-2');
        text-transform: uppercase;
    }

    .desc {
        @include font(16px, 26px);
        margin-bottom: 17px;
        color: map_get($colours, 'n-4');
    }

    a {
        @include font(16px, 24px);
        margin-top: auto;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        .content-block {
            padding-bottom: 49px;
            margin-bottom: 0;
        }


        .title {
            @include font(34px, 40px);
        }

        .desc {
            margin-bottom: 10px;
        }

        a {
            @include font(16px, 24px);
            margin-top: auto;
        }
    }
}
