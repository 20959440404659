/**
Documentation
 */

.small-promo {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 60px;

    .small-promo-tile {
        border: 1px solid map_get($colours, 'n-8');
        display: flex;
        flex-direction: column;
        height: auto;
        margin-bottom: 32px;

        img {
            margin-bottom: 28px;
        }
    }

    .content-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0px 24px;

        &.centre-content{
            text-align: center;
        }
    }

    .cta-button {
        margin-top: 24px;
        margin-bottom: 32px;

        a {
            padding-left: 46px;
            padding-right: 46px
        }
    }

    p {
        color: map_get($colours, 'n-4')
    }

    .icon-block {
        margin: 32px auto 9px auto;
        text-align: center;
    }

    svg.icon {
        width: 100px;
        height: 100px;
    }

    //992 +
    @include media-breakpoint-up(lg) {

        .small-promo-tile {
            height: 100%;
        }

        .cta-button {
            margin-top: auto;
        }
    }
}
