@mixin topLevelItem() {
    @include font(18px, 30px);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    color: map_get($colours, 'p-2');
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    background: none;
    border: none;

    //992 +
    @include media-breakpoint-up(lg) {
        @include animateCustom(#{'color'}, 250ms);
        @include font(16px, 26px);
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }
}


/**
Documentation
 */

#global-nav {
    /**
    Module specific variables
     */
    $accordionBorderColour: rgba(map_get($colours, 'n-6'), 0.3);

    /**
    Default, Mobile first styles
     */
    @include animateCustom(#{'opacity'}, 300ms);
    @include mobileContentPadding($paddingLeft: $grid-gutter-width,
        $paddingRight: $grid-gutter-width);

    display: block;
    width: 100vw;
    height: calc(100vh - #{map_get($globalHeader, "moduleHeight")});
    position: fixed;
    left: 0;
    top: map_get($globalHeader, "moduleHeight");
    z-index: 5;
    padding-bottom: 200px;
    background-color: map_get($colours, 'n-9');
    pointer-events: none; //when hidden / closed
    opacity: 0; //when hidden / closed
    overflow-y: scroll;

    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
    }

    /*
    Open mobile state
     */
    &.open {
        opacity: 1;
        pointer-events: all;
    }

    .main-nav>li {
        border-bottom: solid 1px $accordionBorderColour;
    }

    /*
    Top Level links and / or buttons
    On mobile these can function as accordion items
     */
    .main-nav>li>a,
    .top-lvl-item>.text {
        @include topLevelItem();
    }

    div.top-lvl-item {
        display: flex;
    }

    /*
    Mobile Only accordion trigger
     */
    .btn-accordion-trigger {
        @include btnReset();
        position: relative;
        width: 50px;
        height: 70px;

        .icon {
            position: absolute;
            left: 10px;
            top: 24px;
        }

        .icon.active {
            display: none;
        }

        &.active {
            .icon.default {
                display: none;
            }

            .icon.active {
                display: block;
            }
        }
    }

    /*
    Mobile only supporting links for download + join
     */
    .supporting-links {
        padding: 11px 0 13px 0;
        width: 100%;
        border-bottom: solid 1px $accordionBorderColour;

        li {
            flex-basis: 50%;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            width: 100%;

            .icon {
                width: 25px;
                height: 25px;
                margin-right: 5px;
            }
        }

        .login {
            @include btnPill($fontSize: 18px,
                $lineHeight: 30px,
                $colour_default: map_get($colours, 'p-2'),
                $colour_hover: white,
                $padding: 7px 20px 7px 20px,
                $borderColour_hover: map_get($colours, 'p-2'),
                $bgColour: transparent,
                $bgColour_hover: map_get($colours, 'p-2'));
        }

        .join {
            @include btnPill($fontSize: 18px,
                $lineHeight: 30px,
                $colour_default: map_get($colours, 'p-2'),
                $colour_hover: white,
                $padding: 7px 20px 7px 20px,
                $borderColour_default: map_get($colours, 'p-5'),
                $borderColour_hover: map_get($colours, 'p-5'),
                $bgColour: map_get($colours, 'p-5'),
                $bgColour_hover: map_get($colours, 'p-5'));
        }
    }

    /*
    Default mobile subnav state
    Shared across all subnav types
     */
    .sub-nav {
        @include animateCustom(#{'height'}, 250ms);
        overflow: hidden;
        background-color: map_get($colours, 'n-9');

        .title {
            display: none;
        }
    }


    /*
    When accordion item is active / open
     */
    .top-lvl-item.active {
        .btn-accordion-trigger {
            .icon.default {
                display: none;
            }

            .icon.active {
                display: block;
            }
        }
    }

    /* search bar */
    .search-bar {
        display: block;
        width: 100%;
        position: relative;

        .search-btn {
            position: absolute;
            right: 9px;
            top: 9px;
            background: none;
            border: none;
        }

        .btn-close{
            display: none;
        }
    }



    //992 +
    @include media-breakpoint-up(lg) {
        position: relative;
        height: 90px;
        width: 100%;
        left: auto;
        top: auto;
        padding-bottom: 0;
        opacity: 1;
        pointer-events: all;
        overflow-y: visible;
        background-color: transparent;

        /*
        First level list
         */
        .main-nav {
            height: 100%;
            align-items: center;
            display: flex;
            justify-content: flex-end;

            >li {
                display: flex;
                height: 100%;
                padding-bottom: 0;
                border-bottom: 0;
            }

            a:hover {
                color: map_get($colours, 'p-4');
            }
        }

        /*
        Mobile Only accordion trigger
         */
        .btn-accordion-trigger {
            display: none;
        }

        /*
        Desktop only search link
         */
        .search-link {
            @include animateCustom(#{'color'}, 250ms);
            padding: 0 15px;
            color: map_get($colours, 'p-2');

            &:hover {
                color: map_get($colours, 'p-4');
            }
        }

        /*
        Shared subnav styles
         */
        .sub-nav {
            @include animateCustom(#{'opacity, transform'}, 250ms);
            transform: perspective(1000px) rotateX(-15deg);
            transform-origin: 50% 0;
            opacity: 0;
            pointer-events: none;
            top: 90px;

            .title {
                @include font(22px, 35px);
                display: block;
                padding: 24px 0 22px 0;
                margin-bottom: 29px;
                border-bottom: 4px solid map_get($colours, 'p-4');
                color: map_get($colours, 'p-2');
                font-weight: 700;

            }
        }

        //Active state - triggered by JS, class added by JS
        .sub-nav.active {
            transform: perspective(1000px) rotateX(0);
            opacity: 1;
            pointer-events: all;
        }


        /* search bar */
        .search-bar {
            display: block;
            position: absolute;
            z-index: 10;
            top: 20px;

            .btn-close{
                display: block;
                position: absolute;
                top: 9px;
                right: -40px;
                border: none;
                background: none;
            }
        }

        .hide-on-desktop{
            display: none;
        }
    }
}