/**
Global Utility Nav
Note: this is rendered in two separate locations:
Mobile: as part of off canvas nav
Desktop: above primary nav

Styles are shared, and both instances of markup are found in
\Views\Shared\Global\_Header.cshtml
 */

.utility-nav {
    /**
    Default, Mobile first styles
     */
    margin-top: 20px;

    ul,
    li {
        @include list-unstyled();
        margin: 0;
    }

    a {
        @include font(16px, 35px);
        display: flex;
        align-items: center;
        color:  map_get($colours, 'p-2');
        text-decoration: none;

        .icon {
            width: 18px;
            height: 18px;
            margin-right: 7px;
        }

        &:hover {
            text-decoration: underline;
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {
        align-items: flex-end;
        margin-top: 0;

        ul {
            display: flex;
        }

        a {
            @include animateCustom(#{'background-color, color'}, 250ms );
            @include font(14px, 35px);
            display: block;
            height: 35px;
            padding: 0 15px;
            color: white;
            text-decoration: none;

            .icon {
                width: 17px;
                height: 17px;
                margin-right: 5px;
            }

            &:hover {
                background-color: map_get($colours, 'p-4');
            }
        }

        .login {
            color: map_get($colours, 'p-1');
            font-weight: 700;
        }

        .join {
            padding: 0 18px;
            background-color: map_get($colours, 'p-5');
            color: map_get($colours, 'p-2');
            font-weight: 700;


            &:hover {
                background-color: map_get($colours, 'p-6');
                color: white;
            }
        }
    }
}
