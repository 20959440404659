/**
Documentation
 */

.table-widget {

    /**
    Default, Mobile first styles
     */

    table:not(.performance-table){
        margin-top: 40px;
        margin-bottom: 40px;
        table-layout: auto;
    }

    @include media-breakpoint-up(xs) {
    }

    //576 +
    @include media-breakpoint-up(sm) {
    }

    //768 +
    @include media-breakpoint-up(md) {
    }

    //992 +
    @include media-breakpoint-up(lg) {
    }

    //1200 +
    @include media-breakpoint-up(xl) {
    }
}
