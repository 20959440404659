/**
Performance Line Chart
Note: Highcharts does most of the heavy lifting here
 */
.performance-line-chart {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 55px;
    margin-bottom: 70px;

    .title-block {
        h1 {
            color: map_get($colours, 'p-4');
            text-transform: uppercase;
            margin-bottom: 24px;
        }
    }

    /*
    Highcharts overrides and touchups
     */

    .highcharts-range-selector {
        outline: 0 !important;
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
        padding: 10px;
    }


    .highcharts-range-selector.flatpickr-input {
        top: 10px !important;
        opacity: 0 !important;
    }

    .highcharts-title { display: none !important;}

    .tooltip {
        .date {
            @include font(16px, 18px);
            padding: 0 5px;
            margin: 0;
            font-weight: 700;
        }
        td { padding: 5px;}
        td.strong { font-weight: bold;}

        table {
            margin: 0;
        }
        tbody,
        tr,
        td {
            border: none;
        }
    }

    /*
    Parent element for Highcharts injection
     */
    #chart-embed {
        position: relative;
        border: 1px solid #DDDDDD;
        height: 590px;
        margin-bottom: 60px;
    }

    /*
    Can't use flex - it messages with the other injected elements,
    mostly custom dropdown / datepicker
     */
    .preloader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-40px) translateX(-40px);
    }

    .slide-indicators {
        display: none;
    }

    .key {
        ul {
            @include list-unstyled
        }

        li {
            display: flex;
            margin-bottom: 13px;
        }

        .icon-wrap {
            width: 29px;
            height: 29px;
            border-radius: 3px;
            color: white;
            padding: 2px 5px;
            margin-right: 17px;
        }

        .icon {
            width: 18px;
            height: 18px;
        }

        span {
            @include font(18px, 28px);
            color: black;
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {

        margin-top: 90px;
        margin-bottom: 100px;

        .title-block {
            h1 {
                margin-bottom: 42px;
            }
        }

        #chart-embed {
            margin-bottom: 20px;
        }

        .slide-indicators {
            display: flex;
            justify-content: flex-end;

            .icon {
                color: map_get($colours, 'p-4');
                margin-left: 10px;
            }

            p {
                margin-left: 24px;
                margin-bottom: 0;
            }
        }

        .key {
            padding-left: 110px;

            ul {
                display: flex;
                max-width: 480px;
                flex-wrap: wrap;
            }

            li {
                flex-basis: 240px;
            }
        }
    }
}

