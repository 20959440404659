/**
Documentation
 */

.asset-allocation-pie-chart {
    /*
    Module specific variables
     */
    @include aspectRatio(1, 1);

    .chart-embed {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .preloader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-40px) translateX(-40px);
    }

    //.highcharts-container {
    //    position: absolute;
    //    left: 0;
    //    top: 0;
    //}

    /**
    Default, Mobile first styles
     */

    @include media-breakpoint-up(xs) {
    }

    //576 +
    @include media-breakpoint-up(sm) {
    }

    //768 +
    @include media-breakpoint-up(md) {
    }

    //992 +
    @include media-breakpoint-up(lg) {
    }

    //1200 +
    @include media-breakpoint-up(xl) {
    }
}
