/**
Staff Profile widget
Image and rich text widget
 */

.staff-profile-feature {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    padding-top: 32px;
    padding-bottom: 40px;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: map_get($colours, 'p-5');
    color: map_get($colours, 'p-2');

    .img-block {
        margin-bottom: 28px;
    }


    //768 +
    @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-bottom: 0;
        background-color: transparent;

        .inner-wrap {
            display: flex;
            background-color: map_get($colours, 'p-5');
        }

        .img-block {
            margin-bottom: 0;
            flex-basis: 33%;
            padding: $grid-gutter-width;
            padding-right: $grid-gutter-width / 2;
        }

        .txt-block {
            flex-basis: 67%;
            padding: $grid-gutter-width;
            padding-left: $grid-gutter-width / 2;
        }

    }
}
