/**
Full width video header module
 */

.video-player {
    //Define and override some Ply default
    --plyr-color-main: #51BB18;
    .plyr__video-embed iframe {
        pointer-events: all;
    }
    .plyr__video-wrapper {
        pointer-events: none;
    }

    .video-wrapper {
        /**
        Module specific variables
         */
        $maxHeight_xl: 720px;
        $videoMaxWidth_xl: 1280px;

        /**
        Default, Mobile first styles
         */
        width: 100%;
        position: relative;

        .video-layer {
            @include aspectRatio(16, 9);
            overflow: hidden;

            >div {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
            }
        }

        .content-layer {
            @include animateCustom(#{'opacity'}, 300ms);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            color: white;
            background-color: rgba(black, 0.3);

            .container-fluid {
                height: 100%;

                .row,
                .col-12 {
                    height: 100%;
                }

                .col-12 {
                    display: flex;
                    align-self: center;
                }
            }
        }

        .module-content {
            align-self: center;
            position: relative;
            width: 100%;
        }

        .btn-play-video {
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            text-align: center;

            width: 60px;
            height: 60px;
            border-radius: 9px;
            border: none;

            &:hover{
                background: map_get($colours, 'p-4');
            }
        }

        /*
        Text alignment options
         */
        &.text-align-center {
            .module-content {
                margin: auto;
                text-align: center;
            }
        }

        /*
        Content hidden
         */
        &.content-hidden {
            .content-layer {
                opacity: 0;
                pointer-events: none;
            }
        }


        //992 +
        @include media-breakpoint-up(lg) {
            overflow: hidden;

            .video-layer {
                @include aspectRatio(16, 9);
                overflow: hidden;

                >div {
                    transform: translate(-50%, -50%);
                    position: absolute;
                    width: 100%;
                    left: 50%;
                    top: 50%;
                    right: 0;
                    bottom: 0;
                }
            }

            .btn-play-video {
                width: 78px;
                height: 78px;
            }
        }

        //1200 +
        @include media-breakpoint-up(xl) {
            max-height: $maxHeight_xl;

            &:not(.full-bleed) {
                .video-layer {
                    margin: auto;
                    max-width: $videoMaxWidth_xl;

                    >div {
                        top: 0;
                        left: auto;
                        transform: none;
                        margin: auto;
                    }
                }
            }
        }
    }

    .video-description {
        margin-top: 16px;

        p {
            color: map_get($colours, 'p-2');
            @include font(16px, 24px);
        }
    }

    //768 +
    @include media-breakpoint-up(md) {
        .video-description {
            margin-top: 24px;
        }
    }
}