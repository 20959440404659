/**
Rich Text widget
Note: All child elements are CMS managed via RTE;
as such, they'll inherit styles from global typography settings
As such, this file is primarily structure
 */

.rich-text {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 48px;
    margin-bottom: 41px;
    color: map_get($colours, 'p-2');

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 29px;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 53px;
        margin-bottom: 69px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 26px;
        }
    }
}
