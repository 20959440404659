/**
Documentation
 */

.promo-tile--large {
    /**
    Module specific variables
     */
    $textColour: $white;
    $textColourHover: map_get($colours, 'p-4');

    /**
    Default, Mobile first styles
     */
    //height: 100%;

    a {
        height: 100%;
        display: block;
        position: relative;
        color: $textColour;
        text-decoration: none;

        &:hover {
            color: $textColourHover;
        }
    }

    .img-block {
        position: relative;
    }

    .content-block {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding: $grid-gutter-width;
        align-items: flex-end;
        background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 40%);
    }

    .title {
        margin-bottom: 0;
        font-weight: bold;
    }
}
