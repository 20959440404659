/**
Series of CMS managed links with text and icon
 */

.quick-links-widget {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 56px;
    margin-bottom: 65px;

    ul,
    li {
        @include list-unstyled();
        margin: 0;
    }

    a {
        @include animateCustom(#{'background-color'}, 250ms );
        @include font(16px, 22px);
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 22px;
        margin-bottom: 7px;
        background-color: white;
        border: 1px solid map_get($colours, 'n-8');
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: 700;
        color: map_get($colours, 'p-2');
        text-decoration: none;

        .txt {
            @include animateCustom(#{'transform'}, 250ms);
        }

        &:hover {
            border-color: map_get($colours, 'p-4');

            .txt {
                transform: translateX(5px);
            }
        }
    }

    .icon {
        display: block;
        width: 34px;
        height: 34px;
        margin-right: 30px;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        ul {
            margin: 0 -$grid-gutter-width / 2;
            display: flex;
        }

        li {
            padding: 0 $grid-gutter-width / 2;
            // IE11: Needs to be expanded.
            // flex: 1 0 0;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
        }
        a {
            @include animateCustom(#{'border-color'}, 250ms);
            @include font(18px, 24px);
            flex-direction: column;
            align-items: center;
            height: 100%;
            padding: 30px 5px 30px 5px;
            margin-bottom: 0;
            border: 2px solid map_get($colours, 'n-8');
            text-align: center;

            .icon {
                flex-shrink: 0;
                width: 45px;
                height: 45px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
            }

            .txt {
                @include animateCustom(#{'transform'}, 250ms);
            }

            &:hover {
                .txt {
                    transform: translateX(0);
                }
            }
        }


        /*
        Animated version
         */
        &[data-onload-animation='true']{

            /*
            Default state, before animation - image on RIGHT
             */
            .container-fluid {
                @include animateCustom(#{'transform, opacity'}, 1000ms, 1500ms );
                transform: translateY(50px);
                opacity: 0;
            }


            /*
            DOM loaded, animate 'end' state
             */
            &[data-animated='true']{

                .container-fluid {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }
}
