/**
Shared table styles and utilities
Note:
There are generally two different types / styles of tables:
- General content usage
- Performance tables

Styles for both are contained here. Additionally,
there are some styles for responsive tables; this execustion
does require a parent element with the '.table-responsive-wrap' class
 */


/*
Default, shared table styles
 */
table {

    /**
    Default, Mobile first styles
     */
    @include font(14px, 14px);
    width: 100%;

    thead {
        @include font(16px, 20px);
        font-weight: 700;
        background-color: map_get($colours, 'p-2');
        border: 1px solid map_get($colours, 'p-2');
        color: white;
    }

    tbody {
        border: 1px solid map_get($colours, 'n-8');

        tr {
            border-bottom: 1px solid map_get($colours, 'n-8');

            th {
                background-color: rgba(map_get($colours, 'p-2'), 0.1);
            }
        }
    }

    td,
    th {
        padding: 24px 20px;
    }

    td {
        border-right: 1px solid map_get($colours, 'n-8');
    }
}

.performance-table {
    overflow: hidden;
    border-radius: 5px;
    table-layout: fixed;
    
    thead {
        background-color: map_get($colours, 'p-4');
        border: 1px solid map_get($colours, 'p-4');
        border-radius: 5px;
        overflow: hidden;
        color: map_get($colours, 'p-2');

        th {
            border-right: 1px solid map_get($colours, 'n-8');
        }
    }

    tbody {

        tr {
            &:nth-child(odd){
                background-color: map_get($colours, 'n-9');
            }
        }
    }
}


.asset-allocation-table {
    overflow: hidden;
    border-radius: 5px;
    table-layout: auto;

    thead {
        @include font(14px, 20px);

        background-color: map_get($colours, 'p-2');
        border: 1px solid map_get($colours, 'p-2');
        color: white;

        th {
            padding: 14px 20px 15px 20px;
            border-right: 1px solid map_get($colours, 'n-8');
        }
    }

    .table-subheader {
        @include font(14px, 20px);

        font-weight: 700;
        color: map_get($colours, 'p-2');
        background-color: map_get($colours, 'p-4');

        td {
            padding: 14px 20px 15px 20px;
        }
    }

    tbody {
        @include lineHeight(22px);

        td {
            padding: 14px 20px 15px 20px;
        }
    }
    .allocation-key {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 15px;
    }
}


/*
Responsive execution
 */
.table-responsive-wrap {
    overflow-x: scroll;

    table {
        width: 150vw;
        max-width: 600px;
        table-layout: auto;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        overflow: hidden;

        table {
            width: 100%;
            max-width: 100%;
            table-layout: fixed;
        }
    }
}