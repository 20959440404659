/**
Text and image feature widget
Features two variants:
Default: Text on left; image on right:
Alternate: Image on left; text on right

These layouts are managed via 'alt-layout' class, determined by CMS config

Note: layout for both variants is the same for mobile
 */

.text-image-feature {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 64px;
    margin-bottom: 54px;

    //Default column structure
    .img-col,
    .txt-col {
        @include make-col-ready();
        @include make-col(12);
    }

    .img-col {
        margin-bottom: 22px;

        img {
            padding-bottom: 36px;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            background-color: map_get($colours, 'p-4');
        }
    }

    .txt-block {
        color: map_get($colours, 'p-2');
    }

    .title {
        @include font(28px, 36px);
        text-transform: uppercase;
        margin-bottom: 6px;
    }

    .desc {
        @include font(18px, 26px);
        color: inherit;
        margin-bottom: 42px;
    }

    .btn-default {
        width: 100%;
    }

    //768 +
    @include media-breakpoint-up(md) {
        /*
        Column structure
        Default layout, text on left
         */
        .img-col {
            @include make-col(6);
            order: 2;
        }
        .txt-col {
            @include make-col(6);
            order: 1;
        }

        /*
        Alternate layout, text on right
         */
        &.alt-layout {
            .img-col {
                order: 1;
            }
            .txt-col {
                order: 2;
            }
        }

        /*
        Core styles
         */
        .img-col {
            margin-bottom: 0;

            img {
                padding-bottom: 0;
            }

            &:after {
                display: none;
            }
        }

        .btn-default {
            width: auto;
        }
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 100px;
        margin-bottom: 100px;

        /*
        Apply green line treatment to container
         */
        .container-fluid {
            position: relative;

            &:after {
                @include make-col-ready();
                @include make-col(7);
                @include make-col-offset(1);
                content: '';
                display: block;
                height: 4px;
                background-color: map_get($colours, 'p-4');
                position: absolute;
                left: $grid-gutter-width / 2;
                top: 80px;
                z-index: 5;
            }
        }

        /*
        Column structure
        Default layout, text on left
         */
        .txt-col {
            @include make-col(4);
            @include make-col-offset(1);
        }

        .img-col {
            @include make-col-offset(1);
        }

        /*
        Alternate layout, text on right
         */
        &.alt-layout {
            .container-fluid:after {
                @include make-col-offset(4);
                left: -$grid-gutter-width / 2;
            }
            .txt-col {
                @include make-col(4);
                @include make-col-offset(1);
            }

            .img-col {
                @include make-col-offset(0);
            }
        }

        /*
        Core styles
         */

        .img-block,
        picture,
        img {
            height: 100%;
        }

        img {
            object-fit: cover;
        }

        .txt-block {
            padding-top: 154px;
        }

        .title {
            @include font(38px, 46px);
            margin-bottom: 20px;
        }

        .desc {
            @include font(18px, 31px);
            margin-bottom: 42px;
        }

        .btn-default {
            padding-left: 46px;
            padding-right: 46px;
        }


        /*
        Animated version
         */
        &[data-animate-onscroll='true']{

            /*
            Default state, before animation - image on RIGHT
             */
            .container-fluid:after {
                @include animateCustom(#{'transform'}, 1000ms );
                transform: scaleX(0);
                transform-origin: 0 0;
            }

            .img-block {
                @include animateCustom(#{'transform, opacity'}, 700ms );
                transform: translateY(20px);
                opacity: 0;
            }

            .title,
            .desc,
            .btn-default {
                transform: translateY(30px);
                opacity: 0;
            }

            .title { @include animateCustom(#{'transform, opacity'}, 450ms )};
            .desc { @include animateCustom(#{'transform, opacity'}, 450ms, 200ms )};
            .btn-default { @include animateCustom(#{'transform, opacity'}, 450ms, 400ms )};


            /*
            Default state, before animation - image on LEFT
             */
            &.alt-layout {
                .container-fluid:after {
                    transform-origin: top right;
                }
            }

            /*
            DOM loaded, animate 'end' state
             */
            &[data-animated='true']{

                .container-fluid:after {
                    transform: scaleX(1);
                }

                .img-block {
                    transform: translateY(0);
                    opacity: 1;
                }

                .title,
                .desc,
                .btn-default {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }
}
