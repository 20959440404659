/**
Documentation
 */

.search-header {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    padding-top: 45px;
    padding-bottom: 50px;
    background-color: map_get($colours, 'p-1');

    h2 {
        margin-bottom: 27px;
        text-transform: uppercase;
    }

    .input-wrap {
        position: relative;
    }

    .search-icon {
        position: absolute;
        left: 12px;
        top: 15px;
        width: 22px;
        height: 22px;
    }

    input[type="text"]{
        @include fontSize(22px);
        padding-left: 60px;
        padding-right: 60px;
        margin-bottom: 37px;
        color: map_get($colours, 'p-2');
    }

    .btn-reset {
        @include btnReset();
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;

        &:hover {
            color: map_get($colours, 'p-4');
        }
    }

    button[type='submit']{
        width: 100%;

        &:hover {
            background-color: white;
            color: map_get($colours, 'p-2');
        }
    }

    .description {
        padding-top: 20px;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        padding-top: 86px;
        padding-bottom: 86px;

        h2 {
            margin: 0;
        }

        .inner-wrap {
            display: flex;
            width: 100%;
            align-items: center;
        }

        .input-wrap {
            width: 100%;
            margin-left: $grid-gutter-width;
            margin-right: $grid-gutter-width;
        }

        form {
            display: flex;
            width: 100%;
            margin: 0;
        }

        input[type="text"]{
            margin-bottom: 0;
        }

        button[type='submit']{
            max-width: 200px;
        }
    }
}