$iconSize: 24px;

$iconSize_sm: $iconSize / 2;
$iconSize_lg: $iconSize * 2;
$iconSize_xl: $iconSize * 3;

.icon {
    width: $iconSize;
    height: $iconSize;

    &.size--sm {
        width: $iconSize_sm;
        height: $iconSize_sm;
    }

    &.size--lg {
        width: $iconSize;
        height: $iconSize;
    }

    &.size--xl {
        width: $iconSize_xl;
        height: $iconSize_xl;
    }
}

/*
Fix IE 11 rendering issues
 */
svg.icon {
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
}