/**
Documentation
 */

.mega-nav {

    /**
    Default, Mobile first styles
     */
    ul {
        a {
            @include font(16px, 20px);
            color: map_get($colours, 'p-2');
            font-weight: 400;
            text-decoration: none;
            display: block;
            margin-bottom: 25px;
        }
    }

    .mega-nav-group > a {
        font-weight: 700;
    }

    .mega-nav-column {
        margin-bottom: 15px
    }


    //992 +
    @include media-breakpoint-up(lg) {

        .sub-nav {
            @include make-container();
            position: absolute;
            min-height: 450px;
            left: 0;
            padding: 0 11%;
        }

        /*
        Grid + column structure
         */
        .column-wrap {
            @include make-row();
        }

        .mega-nav-column {
            @include make-col-ready();
            @include make-col(4);
        }

        a {
            @include animateCustom(#{'color'}, 250ms );
            &:hover {
                font-weight: 700;
                color: map_get($colours, 'p-4');
            }
        }
    }
}
