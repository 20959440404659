#global-footer {
    @include mobileContentPadding();
    background-color: map_get($colours, "s-5");
    padding: 60px;
    text-align: center;
    color: map_get($colours, "n-10");
    .section-title {
        @include font(16px, 26px);
        font-weight: 700;
        margin-bottom: 16px;
    }
    .horizontal-list {
        @include list-unstyled();
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0;
        li {
            margin-right: 20px;
            &:last-child {
                margin: 0;
            }
        }
    }
    .site-logo {
        display: block;
        width: 205px;
        margin: auto;
        margin-bottom: 26px;
    }
    .footer-menu-column {
        ul,
        li {
            @include list-unstyled();
            margin-bottom: $grid-gutter-width;
        }
        a {
            @include font(16px, 26px);
            color: map_get($colours, "n-10");
            font-weight: 400;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                color: map_get($colours, "s-2");
            }
        }

    }
    .social-links {
        margin-bottom: $grid-gutter-width;
        .btn-social {
            color: map_get($colours, "n-10");
            &:hover {
                color: map_get($colours, "s-2");
            }
        }
    }
    .app-links {
        margin-bottom: 50px;
    }
    .awards {
        margin-bottom: $grid-gutter-width;
        li img {
            height: 60px;
            width: auto;
        }
    }
    .acknowledgement {
        .inner {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: $grid-gutter-width 0;
            border-top: 1px solid map_get($colours, "n-5");
            .acknowledgement-text {
                @include font(12px, 20px);
                text-align: left;
            }
        }
        img {
            display: block;
            width: 87px;
            height: 29px;
            margin-bottom: 0;
            margin-right: $grid-gutter-width;
            flex-shrink: 0;
        }
    }
    .terms-legals {
        @include font(12px, 20px);
        .inner {
            padding: $grid-gutter-width 0 0 0;
            border-top: solid 1px #AAAAAA;
        }

        .disclaimer-text,
        a,
        p {
            font-size: inherit;
            line-height: inherit;
        }
    }

    .legal-links {
        @include list-unstyled();
        margin-bottom: $grid-gutter-width;
        li {
            display: inline-block;
            padding: 0 8px 0 4px;
            line-height: inherit;
            position: relative;
            &:after {
                content: '';
                display: block;
                width: 1px;
                height: 13px;
                position: absolute;
                top: 3px;
                right: 0;
                background-color: map_get($colours, "n-10");
            }
            &:last-child:after {
                display: none;
            }
        }
        a {
            color: map_get($colours, "n-10");
            text-decoration: none;
        }
    }
    .disclaimer-text {
        margin-bottom: $grid-gutter-width;
    }
    .credit {
        margin-top: $grid-gutter-width;
        a {
            color: map_get($colours, "n-10");
            text-decoration: none;

            &:hover {
                color: map_get($colours, "n-2");
                text-decoration: underline;
            }
        }
    }

    // 992 +
    @include media-breakpoint-up(lg) {
        text-align: left;
        .horizontal-list {
            justify-content: flex-start;
            li {
                margin: 0 $grid-gutter-width 0 0;
                &:last-child {
                    margin: 0;
                }
            }
        }
        .site-logo {
            margin: 0;
        }
        .social-links {
            .horizontal-list {
                justify-content: flex-start;
                li {
                    margin-left: 0;
                }
                a {
                    margin-left: 0;
                }
            }
        }
        .supporting-content {
            margin-top: 52px;
        }
        .app-links {
            margin-bottom: 0;
        }
        .awards {
            margin-bottom: 0;
        }
        .acknowledgement {
            display: flex;
            margin: $grid-gutter-width 0 0 0;
            .col-12 {
                padding: 0;
            }
            .inner {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: $grid-gutter-width 0;
                border-top: 1px solid map_get($colours, "n-5");
                .acknowledgement-text {
                    @include font(12px, 20px);
                }
            }
            img {
                display: block;
                width: 87px;
                height: 29px;
                margin-bottom: 0;
                margin-right: $grid-gutter-width;
                flex-shrink: 0;
            }
            p {
                margin: 0;
            }
        }
        .terms-legals {
            .inner {
                padding: $grid-gutter-width 0 0 0;
                border-top: 1px solid map_get($colours, "n-5");
            }
            .disclaimer-text,
            a,
            p {
                font-size: inherit;
                line-height: inherit;
            }
        }
        .copyright {
            display: flex;
            .credit {
                margin-left: auto;
            }
        }
    }
}
